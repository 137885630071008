import React from "react"
import {graphql} from "gatsby"
import {fetchHomePage} from '../api'
import {GENRES, GENRES_TABLE} from '../const'

import Layout from '../components/Layout'
import Metadata from '../components/Metadata'
import Rows from "../components/Rows"
import Jumbotron from "../components/Jumbotron"

import './blog.css'

// const sortByDate = movie => movie.sort((a, b) => new Date(a.movie.release) - new Date(b.movie.release))

const getLastReleased = movies => movies.sort((a,b) => new Date(b.movie.release) - new Date(a.movie.release)).filter(i => {
  // (GENRES_TABLE[23])
  let date = new Date(i.movie.release)
  let now = Date.now()
  return date <= now
})


const getComingSoon = movies => movies.filter(i => {
  let date = new Date(i.movie.release)
  let now = Date.now()
  return date >= now
})

const getGenre = (genre, movies) => movies.filter(i => {
  if(!i.movie.genres) return false
  let num = GENRES[genre]
  num = num.toString()
  // ('Does ', i.movie.genres, 'includes ', num , i.movie.genres.includes(num))
  return i.movie.genres.includes(num)
})

const getPopular = movies => movies.sort((a,b) => b.analytics.views_last_month - a.analytics.views_last_month )

const getTrending = movies => movies.sort((a,b) => b.analytics.views_last_three_days - a.analytics.views_last_three_days )

const getHighAvgTimes = (movies) => movies.sort((a,b) => b.analytics.avg_time_on_page - a.analytics.avg_time_on_page)


const getStreaming = movies => movies.filter(i => {
  let hasMovie = false
  i.videos.forEach(video => {
    if (video.type === "movie") hasMovie = true
  })
  return hasMovie
})

class HomePage extends React.Component {
  state = {
    movies: [],
  }

  // async componentDidMount(){
  //   const result = await fetchHomePage()
  //   this.setState({
  //     movies: result,
  //   })
  // }

  render() {
    // let all = this.props.data.allInternalMovies.edges.map(i =>  i.node).filter(i => i.title !== null)
    // const allMovies = getStreaming(all)
    // const allpopularMovies = getPopular([...allMovies])

    // const lastReleased = getLastReleased(allMovies).slice(0, 40)
    // const popular = allpopularMovies.slice(0, 40)
    // const highOnPageTime = getHighAvgTimes([...allMovies]).filter(i => !popular.slice(0,9).includes(i)).slice(0, 40)
    // const action = getGenre('Action', allpopularMovies).slice(0, 30)
    // const adventure = getGenre('Adventure', allpopularMovies).slice(0, 30)
    // const animation = getGenre('Animation', allpopularMovies).slice(0, 30)
    // const comedy = getGenre('Comedy', allpopularMovies).slice(0, 30)
    // const thriller = getGenre('Thriller', allpopularMovies).slice(0, 30)
    // const documentary = getGenre('Documentary', allpopularMovies).slice(0, 30)
    // const drama = getGenre('Drama', allpopularMovies).slice(0, 30)
    // const fantasy = getGenre('Fantasy', allpopularMovies).slice(0, 30)
    // // const trending = getTrending([...allMovies])
    // // const comingSoon = getComingSoon(allMovies)
    // const jumboMedia = [...popular.slice(0,3), ...animation.slice(0,2), ...documentary.slice(0,2)]
    return(
      <Layout>
        {/* <Metadata title="Films, Dessins Animés et Documentaires Gratuits en Streaming" pathname={this.props.location.pathname}/>
        <Jumbotron movies={jumboMedia}/>
        <Rows movies={popular} title={'Populaire'}/>
        <Rows movies={highOnPageTime} title={'Tendances actuelles'}/>
        <Rows movies={lastReleased} title={'Dernières sorties'}/>
        <Rows movies={animation} title={'Animation'}/>
        <Rows movies={[...action, ...adventure]} title={'Action/Aventure'}/>
        <Rows movies={comedy} title={'Comédie'}/>
        <Rows movies={thriller} title={'Thriller'}/>
        <Rows movies={documentary} title={'Documentaires'}/>
        <Rows movies={drama} title={'Drame'}/>
        <Rows movies={fantasy} title={'Fantastique'}/> */}
        {/* <Rows movies={comingSoon} title={'Prochaines sorties'}/> */}
      </Layout>
    )
  }
}

export default HomePage

// export const query = graphql`
// {
// 	allInternalMovies{
//     edges{
//       node{
//         id
//         title
//         slug
//         language
//         synopsis
//         poster
//         videos {
//           type
//         }
//         analytics {
//           views_last_month
//           views_last_three_days
//           total_views
//           avg_time_on_page
//         }
//         movie {
//           alternative_id
//           original_title
//           original_language
//           original_country
//           budget
//           box_office
//           runtime
//           release
//           genres
//         }
//       }
//     }
//   }
// }
// `

/* 
  Rows on movie page under the video
  * People who have seen this video have also seen (if any)
    If not logged in, means -> Users that stayed long and played video on <Page> page also stayed long on <Page>

  Possible rows
  * Keep watching (if any)
  * Top picks(if any)
  * Popular on cinetimes
  * 5 personnalized genre rows
  * Because you watched <Video>
  * Users who watched <Video> also watched <Row of videos>
  * Oscar winners
  * Other movies with <Actor>
  *  Vos articles vus récemment et vos recommandations en vedette -- Inspiré par vos achats -- 
*/